import { Link } from "react-router-dom";
import thankyouImg from "../../Images/Logos/Thankyou.jpg";

import { useEffect, useState } from "react";
import Shimmer from "../utils/Shimmer Comp/Shimmer";
import { useTranslation } from "react-i18next";

const Thankyou = () => {
  const [shimmerEffect, setShimmerEffect] = useState(true);
  const { t } = useTranslation();

  const { homeBtn, thankYou, getBack } = t("common");

  useEffect(() => {
    const intervalId = setTimeout(() => {
      setShimmerEffect(false);
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);
  return shimmerEffect ? (
    <Shimmer />
  ) : (
    <section className="relative">
      <img
        src={thankyouImg}
        className="h-screen w-full object-cover"
        alt="thankyouImg"
      />
      <div className="absolute lg:top-1/3 lg:left-[15%] top-1/3 left-[5%] flex flex-col items-center">
        <h1 className="lg:text-7xl xs:text-4xl xxs:text-xl font-georgia text-mediumBlue font-medium ">
          {thankYou}
        </h1>
        <p className="pt-3 sm:text-sm text-xs pl-5 md:text-base sm:w-full">
          {getBack}
        </p>
        <Link to={"/"}>
          <button className="styleButton md:mt-8 xxs:mt-4 lg:px-8 lg:py-3 sm:px-4 sm:py-2 xxs:p-1 ">
            {homeBtn}
          </button>
        </Link>
      </div>
    </section>
  );
};

export default Thankyou;
