import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Aos from "aos";
import "aos/dist/aos.css";

const Banner = ({ title, description, ImageUrl }) => {
  const { t } = useTranslation();

  useEffect(() => {
    Aos.init({ duration: 1000, once: true });
  }, []);

  const { contactBtn } = t("common");
  return (
    <div className="flex sm:flex-row flex-col gap-5 xs:gap-0 justify-around items-center py-2">
      <div
        data-aos="fade-right"
        className="sm:w-1/2 px-4 sm:px-0 flex flex-col sm:items-start items-center"
      >
        <h1 className="lg:text-5xl md:text-3xl xs:text-xl font-semibold">
          {title}{" "}
        </h1>
        <p className="md:my-10 my-5 sm:text-base text-sm">{description}</p>

        <Link to="/contactus">
          <button className="border border-lightGray sm:px-8 sm:py-3 px-4 py-2 rounded hover:bg-lightGray hover:text-black font-medium duration-500 ease-in">
            {t(contactBtn)}
          </button>
        </Link>
      </div>

      <img
        data-aos="fade-left"
        src={ImageUrl}
        alt={ImageUrl}
        className="lg:w-[26rem] sm:w-80 xxs:h-[30rem] w-full h-fit object-cover rounded my-5"
      />
    </div>
  );
};

export default Banner;
