import LinkedInPNG from "../../Images/Logos/SocialmediaPng/Linkedin.png";
import facebookPNG from "../../Images/Logos/SocialmediaPng/Facebook.png";
import instagramPNG from "../../Images/Logos/SocialmediaPng/instagram.png";
import whatsappPNG from "../../Images/Logos/SocialmediaPng/whatsapp.png";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const SocialMedia = () => {
  const phoneNumber = "8977617888"; // Replace with the desired phone number
  const message = "Hello, I would like to know more about your services.";

  const whatsappURL = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(
    message
  )}`;

  const { t } = useTranslation();

  const { followUs } = t("headerAndFooter");
  return (
    <div className="flex flex-col lg:flex-row gap-2 items-center">
      <p className="">{followUs} : </p>
      <div className="flex items-center gap-2">
        <Link to="https://www.linkedin.com/" target="_blank">
          <img
            src={LinkedInPNG}
            className="w-6 h-6 object-cover cursor-pointer transform hover:scale-150 duration-500"
            alt="LinkedInPNG"
          />
        </Link>
        <Link
          to="https://www.facebook.com/profile.php?id=61557448979333"
          target="_blank"
        >
          <img
            src={facebookPNG}
            className="w-6 h-6 object-cover cursor-pointer transform hover:scale-150 duration-500"
            alt="facebookPNG"
          />
        </Link>

        <Link to="https://www.instagram.com/nd_health.ai/" target="_blank">
          <img
            src={instagramPNG}
            className="w-6 h-6 object-cover cursor-pointer transform hover:scale-150 duration-500"
            alt="instagramPNG"
          />
        </Link>
        <Link to={"https://wa.me/+918977617888"} target="_blank">
          <img
            src={whatsappPNG}
            className="w-6 h-6 object-cover cursor-pointer transform hover:scale-150 duration-500"
            alt="whatsappPNG"
          />
        </Link>
      </div>
    </div>
  );
};

export default SocialMedia;
