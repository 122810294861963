import diabetesPng from "../../Images/Logos/Diabetes-results/diabetesPng.png";
import medicationPng from "../../Images/Logos/Diabetes-results/medicationPng.png";
import insulinPng from "../../Images/Logos/Diabetes-results/insulinPng.png";
import sugarLevelPng from "../../Images/Logos/Diabetes-results/sugarLevelPng.png";
import weightPng from "../../Images/Logos/Diabetes-results/weightPng.png";
import { Link, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import twinHealthLogo from "../../Images/Logos/Twin Logos/twin logo.png";
import twinHealthLogo1 from "../../Images/Logos/Twin Logos/Twin white text Logo.png";
import { useEffect } from "react";

export const DiabetesResults = [
  {
    imgUrl: diabetesPng,
    heading: "1.9%",
    subHeading: "Average HbA1c drop",
  },
  {
    imgUrl: medicationPng,
    heading: "90%",
    subHeading: "Have reduced or eliminated medication",
  },
  {
    imgUrl: insulinPng,
    heading: "92%",
    subHeading: "Have reduced or eliminated insulin",
  },
  {
    imgUrl: sugarLevelPng,
    heading: "90%",
    subHeading: "Have seen reduction in sugar levels",
  },
  {
    imgUrl: weightPng,
    heading: "3+ Kg",
    subHeading: "Average weight-loss sustained within months",
  },
];

export const languageOptions = [
  {
    value: "select",
    label: "Select",
  },
  {
    value: "telugu",
    label: "Telugu",
  },
  {
    value: "hindi",
    label: "Hindi",
  },
  {
    value: "english",
    label: "English",
  },
];

export const timeSlotOption = [
  {
    value: "select",
    label: "Select",
  },
  {
    value: "10am",
    label: "10 AM - 1 PM",
  },
  {
    value: "1pm",
    label: "1 PM - 5 PM",
  },
];

export const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

export const EnquiryAndAssociatedSection = () => {
  const { t } = useTranslation();
  const { enquiryBtn } = t("common");
  const { association } = t("headerAndFooter");

  // const [image, setImage] = [twinHealthLogo];

  // console.log(image);

  // useEffect(() => {
  //   const colorChangeBasedOnBg = () => {
  //     const body = document.body;
  //     console.log(body);
  //     console.log(body.classList.contains("bg-mediumBlue"));
  //     if (body.classList.contains("bg-mediumBlue")) {
  //       setImage(twinHealthLogo1);
  //     }
  //   };
  //   colorChangeBasedOnBg();
  // }, []);

  return (
    <div className="flex flex-col gap-5 items-center">
      {/* <div className="flex items-center gap-2">
        <p className="text-sm hidden md:block">{association}</p>
        <img src={twinHealthLogo} className="w-20" alt={twinHealthLogo} />
      </div> */}
      {/***************** Enquire Now Button *****************/}

      <Link to={"/contactus"}>
        <button className="styleButton px-8 py-3 mb-5">{enquiryBtn}</button>
      </Link>
    </div>
  );
};
