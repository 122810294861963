import { NavLink } from "react-router-dom";
import Logo from "../../Images/Logos/white Color side-1.png";
import twinHealthLogo from "../../Images/Logos/Twin Logos/Twin white text Logo.png";
import { RxHamburgerMenu, RxCross2 } from "react-icons/rx";
import { useEffect, useState } from "react";
import LanguageSelector from "../utils/LanguageSelector/LanguageSelector";
import { useTranslation } from "react-i18next";

const NavLinks = () => {
  const [showDropDown, setShowDropDown] = useState(false);

  const { t } = useTranslation();

  const {
    home,
    programs,
    diabetes,
    dyslipedimia,
    pcod,
    obesity,
    psoriasis,
    antiaging,
    nafld,
    shop,
    whatwedo,
    blog,
    contactus,
  } = t("headerAndFooter");

  const handleOutsideClick = (e) => {
    if (showDropDown && !e.target.closest(".programs")) {
      closeDropDown();
    }
  };

  const toggleDropDown = () => {
    setShowDropDown(!showDropDown);
  };

  const closeDropDown = () => {
    setShowDropDown(false);
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [showDropDown]);

  return (
    <div className="sm:flex md:gap-5 lg:gap-12 sm:flex-row sm:gap-3 xs:gap-2 items-center xxs:space-y-10 sm:space-y-0 xxs:text-sm lg:text-base">
      <li className="hover:text-Orange">
        <NavLink to="/">{t(home)}</NavLink>
      </li>

      {/* <li>{t("greeting")}</li> */}

      <li className="relative programs">
        <button
          className="border-none hover:text-Orange relative "
          onClick={toggleDropDown}
        >
          {t(programs)}
        </button>
        {/************** Drop Down *****************/}
        {showDropDown && (
          <div className="absolute sm:top-[3.5rem] rounded bg-mediumBlue/95 p-3 z-10 navbar w-36">
            <ul className="flex flex-col gap-3 ">
              <li className="hover:text-Orange" onClick={closeDropDown}>
                <NavLink to="/programs/diabetes-reversal-program">
                  {t(diabetes)}
                </NavLink>
              </li>
              <li className="hover:text-Orange" onClick={closeDropDown}>
                <NavLink to="/programs/dyslipidemia-treatment-program">
                  {t(dyslipedimia)}
                </NavLink>
              </li>
              <li className="hover:text-Orange" onClick={closeDropDown}>
                <NavLink to="/programs/pcod-management-program">
                  {t(pcod)}
                </NavLink>
              </li>
              <li className="hover:text-Orange" onClick={closeDropDown}>
                <NavLink to="/programs/obesity-management-program">
                  {t(obesity)}
                </NavLink>
              </li>
              <li className="hover:text-Orange" onClick={closeDropDown}>
                <NavLink to="/programs/psoriasis-treatment-program">
                  {t(psoriasis)}
                </NavLink>
              </li>
              <li className="hover:text-Orange" onClick={closeDropDown}>
                <NavLink to="/programs/anti-aging-program">
                  {t(antiaging)}
                </NavLink>
              </li>
              <li className="hover:text-Orange" onClick={closeDropDown}>
                <NavLink to="/programs/nafld-program">{t(nafld)}</NavLink>
              </li>
            </ul>
          </div>
        )}
      </li>
      <li className="hover:text-Orange">
        <NavLink to="http://ndhealth.shop" target="_blank">
          {t(shop)}
        </NavLink>
      </li>
      <li className="hover:text-Orange">
        <NavLink to="/whatwedo">{t(whatwedo)}</NavLink>
      </li>
      <li className="hover:text-Orange">
        <NavLink to="/nd-health-ai-blog">{t(blog)}</NavLink>
      </li>
      <li className="hover:text-Orange">
        <NavLink to="/contactus">{t(contactus)}</NavLink>
      </li>
    </div>
  );
};

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [showDropDown, setShowDropDown] = useState(false);

  const { t } = useTranslation();

  const {
    home,
    programs,
    diabetes,
    dyslipedimia,
    pcod,
    obesity,
    psoriasis,
    antiaging,
    nafld,
    shop,
    whatwedo,
    blog,
    contactus,
    association,
  } = t("headerAndFooter");

  const openSlider = () => {
    setIsOpen(!isOpen);
  };

  const closeSlider = () => {
    setIsOpen(false);
  };

  const handleDropDown = (e) => {
    e.stopPropagation();
    setShowDropDown(!showDropDown);
  };

  const closeDropDown = () => {
    setShowDropDown(false);
    closeSlider();
  };

  // Close the DropDown if user clicks outside of the DropDown

  const handleOutsideClick = (e) => {
    if (isOpen && !e.target.closest(".side-navbar")) {
      closeSlider();
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [isOpen]);

  return (
    <header
      className={`flex justify-between items-center bg-mediumBlue py-3 md:px-4 px-1 relative w-full`}
    >
      <NavLink to="/">
        <img
          src={Logo}
          alt="logo"
          className="hover:scale-105 ease-out duration-500 md:w-56 w-48"
        />
      </NavLink>

      <ul className="text-lightWhite xs:text-sm sm:text-base hidden sm:block ">
        <NavLinks />
      </ul>

      {/*************** Mobile Responsive Navbar ********************/}

      {isOpen && (
        <ul className="text-lightWhite sm:hidden block absolute top-[3.4rem] xs:top-[3.8rem] bg-darkBlue/80 z-10 right-0 p-5 w-56 h-screen items-center text-center side-navbar">
          <div className="flex flex-col items-center gap-10 w-full">
            <div className="sm:flex lg:gap-16 md:gap-10 sm:flex-row sm:gap-5 xs:gap-2 items-center space-y-5 sm:space-y-0 text-sm">
              <li className="hover:text-Orange" onClick={closeSlider}>
                <NavLink to="/">{t(home)}</NavLink>
              </li>
              <li className="relative">
                <button
                  className="border-none hover:text-Orange relative "
                  onClick={handleDropDown}
                >
                  {t(programs)}
                </button>
                {/************** Drop Down *****************/}
                {showDropDown && (
                  <div className="absolute w-32 rounded bg-mediumBlue/95 p-3 z-10">
                    <ul className="flex flex-col gap-5">
                      <li className="hover:text-Orange" onClick={closeDropDown}>
                        <NavLink to="/programs/diabetes-reversal-program">
                          {t(diabetes)}
                        </NavLink>
                      </li>
                      <li className="hover:text-Orange" onClick={closeDropDown}>
                        <NavLink to="/programs/dyslipidemia-treatment-program">
                          {t(dyslipedimia)}
                        </NavLink>
                      </li>
                      <li className="hover:text-Orange" onClick={closeDropDown}>
                        <NavLink to="/programs/pcod-management-program">
                          {t(pcod)}
                        </NavLink>
                      </li>
                      <li className="hover:text-Orange" onClick={closeDropDown}>
                        <NavLink to="/programs/obesity-management-program">
                          {t(obesity)}
                        </NavLink>
                      </li>
                      <li className="hover:text-Orange" onClick={closeDropDown}>
                        <NavLink to="/programs/psoriasis-treatment-program">
                          {t(psoriasis)}
                        </NavLink>
                      </li>
                      <li className="hover:text-Orange" onClick={closeDropDown}>
                        <NavLink to="/programs/anti-aging-program">
                          {t(antiaging)}
                        </NavLink>
                      </li>
                      <li className="hover:text-Orange" onClick={closeDropDown}>
                        <NavLink to="/programs/nafld-program">
                          {t(nafld)}
                        </NavLink>
                      </li>
                    </ul>
                  </div>
                )}
              </li>
              <li className="hover:text-Orange" onClick={closeSlider}>
                <NavLink to="http://ndhealth.shop">{t(shop)}</NavLink>
              </li>

              <li className="hover:text-Orange" onClick={closeSlider}>
                <NavLink to="/whatwedo">{t(whatwedo)}</NavLink>
              </li>

              <li className="hover:text-Orange" onClick={closeSlider}>
                <NavLink to="/nd-health-ai-blog">{t(blog)}</NavLink>
              </li>
              <li className="hover:text-Orange" onClick={closeSlider}>
                <NavLink to="/contactus">{t(contactus)}</NavLink>
              </li>
              <div className="text-black">
                <LanguageSelector />
              </div>
            </div>

            {/* <div className="relative flex items-center gap-2 px-0">
              <p className="text-xs gap-1">{association}</p>
              <img src={twinHealthLogo} className="w-16" alt="twinHealthLogo" />
            </div> */}
          </div>
        </ul>
      )}

      {/**************  && Humburger Menu **************/}

      <div className="flex items-center md:gap-5 gap-1">
        <div className="sm:block hidden">
          <LanguageSelector />
        </div>
        {/****      Association    ****/}

        {/* <div className="flex flex-col items-center text-white">
          <p className="text-[11px] hidden md:block">{association}</p>
          <img src={twinHealthLogo} className="w-20" alt="twinHealthLogo" />
        </div> */}
        {/***    Humburger Menu    ***/}
        <div className="sm:hidden block text-white">
          {isOpen ? (
            <RxCross2 size={26} onClick={closeSlider} />
          ) : (
            <RxHamburgerMenu size={25} onClick={openSlider} />
          )}
        </div>
      </div>
    </header>
  );
};

export default Header;
