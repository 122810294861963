import "./App.css";
import Body from "./components/Pages/Body";
import Footer from "./components/Footer/Footer";
import Header from "./components/Pages/Header";
import ContactUs from "./components/Pages/ContactUs";
import WhatWeDo from "./components/Pages/WhatWeDo";
import Diabetes from "./components/Programs/Diabetes";
import PCOD from "./components/Programs/PCOD";
import Dyslipedimia from "./components/Programs/Dyslipedimia";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Thankyou from "./components/Pages/Thankyou";
import PrivacyAndPolicy from "./components/Privacy and Terms&Conditions/PrivacyAndPolicy";
import Terms from "./components/Privacy and Terms&Conditions/Terms";
import { createContext, useEffect, useState } from "react";
import Obesity from "./components/Programs/Obesity";
import LandingPage from "./components/LandingPage/LandingPage";
import ThankyouLand from "./components/LandingPage/ThankyouLand";
import Psoriasis from "./components/Programs/Psoriasis";
import StallPage from "./components/StallPage/StallPage";
import ThankyouStall from "./components/StallPage/ThankyouStall";
import AntiAging from "./components/Programs/AntiAging";
import NAFLD from "./components/Programs/NAFLD";
import Error from "./components/utils/Error";
import "./components/i18next";
import Blog from "./components/Pages/Blog";
import { ScrollToTop } from "./components/utils/constant";

// Context Provider

export const ContextProvider = createContext();

function App() {
  const [shimmerEffect, setShimmerEffect] = useState(true);
  const [loader, setLoader] = useState(true);

  useEffect(() => {
    // Simulate loading delay
    const timeout = setTimeout(() => {
      setLoader(false);
    }, 4000);

    // Cleanup
    return () => clearTimeout(timeout);
  }, []);

  useEffect(() => {
    const timeId = setTimeout(() => {
      setShimmerEffect(false);
      setLoader(true);
    }, 1500);

    return () => clearTimeout(timeId);
  }, []);

  return (
    <BrowserRouter>
      <ContextProvider.Provider
        value={{
          shimmerEffect,
          setShimmerEffect,
          loader,
          setLoader,
        }}
      >
        <ScrollToTop />
        <Header />
        <Routes>
          <Route path="*" element={<Error />} />
          <Route path="/landingpage" element={<LandingPage />} />
          <Route path="/thankyouland" element={<ThankyouLand />} />
          <Route path="/stallpage" element={<StallPage />} />
          <Route path="/thankyoustall" element={<ThankyouStall />} />

          <Route
            path="/"
            element={
              <div className="font-poppins">
                <Body />
              </div>
            }
          />

          {/****************    Programms Page     ****************/}

          <Route
            path="/programs/*"
            element={
              <div className="font-poppins">
                <Routes>
                  <Route
                    path="diabetes-reversal-program"
                    element={<Diabetes />}
                  />
                  <Route
                    path="dyslipidemia-treatment-program"
                    element={<Dyslipedimia />}
                  />
                  <Route path="pcod-management-program" element={<PCOD />} />
                  <Route
                    path="obesity-management-program"
                    element={<Obesity />}
                  />
                  <Route
                    path="psoriasis-treatment-program"
                    element={<Psoriasis />}
                  />
                  <Route path="anti-aging-program" element={<AntiAging />} />
                  <Route path="nafld-program" element={<NAFLD />} />
                </Routes>
              </div>
            }
          />

          {/****************    WhatWeDo     ****************/}

          <Route
            path="/whatwedo"
            element={
              <div className="font-poppins">
                <WhatWeDo />
              </div>
            }
          />

          {/****************    Blog     ****************/}

          <Route
            path="/nd-health-ai-blog"
            element={
              <div className="font-poppins">
                <Blog />
              </div>
            }
          />

          {/****************    Contact Us     ****************/}

          <Route
            path="/contactus"
            element={
              <div className="font-poppins">
                <ContactUs />
              </div>
            }
          />

          {/****************    Thank You     ****************/}

          <Route
            path="/thankyou"
            element={
              <div className="font-poppins">
                <Thankyou />
              </div>
            }
          />

          {/****************    Privacy and Policy     ****************/}

          <Route
            path="/privacy-policy"
            element={
              <div className="font-poppins">
                <PrivacyAndPolicy />
              </div>
            }
          />

          {/****************    Terms and Conditions     ****************/}

          <Route
            path="/terms-and-conditions"
            element={
              <div className="font-poppins">
                <Terms />
              </div>
            }
          />
        </Routes>{" "}
        <Footer />
      </ContextProvider.Provider>
    </BrowserRouter>
  );
}
export default App;
