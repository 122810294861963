import comingsoonImg from "../../Images/comingsoon1.jpg";
import { Link } from "react-router-dom";

import { useTranslation } from "react-i18next";
import MetaInfo from "../Meta/Meta-Info";

const Blog = () => {
  const { t } = useTranslation();
  const { coming, tuned, homeBtn } = t("shop");

  return (
    <div>
      <MetaInfo
        pageTitle={
          "ND Health AI Blog | Latest Insights on Health AI Innovations"
        }
        pageDescription={
          "Explore ND Health AI Blog for cutting-edge health AI trends and innovations. Stay informed with our latest posts. Visit now! "
        }
        pageKeywords={
          "ND Health AI Blog, ai in healthcare blog,ai healthcare articles"
        }
        canonicalUrl={"https://ndhealth.ai/nd-health-ai-blog"}
      />
      <img
        src={comingsoonImg}
        className="w-full h-screen object-cover"
        alt="comingsoonImg"
      />
      <div className="absolute sm:top-1/4 lg:left-[5%] sm:left-[2%] bottom-[10%] left-[20%] flex flex-col sm:items-start items-center">
        <h1 className="lg:text-4xl md:text-3xl text-xl font-semibold">
          {coming}
        </h1>
        <p className="pt-2 md:pb-10 pb-3">{tuned}</p>
        <Link to="/">
          <button className="styleButton sm:px-5 border-none sm:py-2 px-2 py-1 font-medium rounded-md">
            {homeBtn}
          </button>
        </Link>
      </div>
    </div>
  );
};

export default Blog;
