import LogoImg from "../../Images/Logos/white Color side-1.png";

export const ConsultBtn = () => {
  return (
    <button className="text-white font-medium rounded px-5 py-3 bg-Orange hover:bg-white hover:text-Orange duration-500 ease-out">
      Book a Consultation
    </button>
  );
};

const LandHeader = () => {
  return (
    <header className="relative z-10">
      <div className="flex justify-between items-center py-3 px-10 fixed bg-mediumBlue min-w-full">
        <img
          src={LogoImg}
          alt="LogoImg"
          className="md:w-44 w-36 mx-auto md:mx-0"
        />
        <div className="hidden md:block">
          <ConsultBtn />
        </div>
      </div>
    </header>
  );
};

export default LandHeader;
