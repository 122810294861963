import aboutusImg from "../../Images/LandingPage/AI.jpg";

const StallAboutUs = () => {
  return (
    <div className="bg-mediumBlue p-5 text-white flex flex-col items-center">
      <div className="flex md:flex-row flex-col justify-around items-center">
        <img
          src={aboutusImg}
          alt="aboutusImg"
          className="lg:w-96 lg:h-60 md:w-72 md:h-56 rounded"
        />
        <div className="md:w-1/2 w-full">
          <h2 className="md:text-3xl text-xl font-semibold sm:text-center sm:pt-4">
            About Us
          </h2>
          <p className="py-5 leading-7 md:text-base text-sm inline-block">
            ND Health offers personalized healthcare solutions using AI. They
            address chronic conditions like diabetes, dyslipidemia, PCOD, and
            more. Complimentary consultation calls and AI technology enhance
            their approach.
          </p>
        </div>
      </div>
      <p className="py-5 md:text-xl font-semibold text-base">
        Take control of your well-being with a future-focused approach. Contact
        ND Health AI and see if their AI solutions are right for you!
      </p>
      <button className="text-white font-medium rounded px-5 py-3 bg-Orange hover:bg-white hover:text-Orange duration-500 ease-out">
        Book a Consultation
      </button>
    </div>
  );
};

export default StallAboutUs;
