import Logo from "../../Images/Logos/white Color side-1.png";
import twinHealthLogo from "../../Images/Logos/Twin Logos/Twin white text Logo.png";
import { Link, NavLink } from "react-router-dom";
import { useState } from "react";
import SocialMedia from "./SocialMedia";

import { useTranslation } from "react-i18next";

const NavLinks = () => {
  const [showDropDown, setShowDropDown] = useState(false);

  const { t } = useTranslation();

  const { home, shop, whatwedo, blog, contactus, quickLinks } =
    t("headerAndFooter");

  return (
    <div className="flex flex-col gap-2 ">
      <li className="text-base  font-semibold">{quickLinks}</li>
      <li className="hover:underline text-lightGray ">
        <NavLink to="/">{home}</NavLink>
      </li>
      <li className="hover:underline text-lightGray ">
        <NavLink to="http://ndhealth.shop" target="_blank">
          {shop}
        </NavLink>
      </li>
      <li className="hover:underline text-lightGray ">
        <NavLink to="/whatwedo">{whatwedo}</NavLink>
      </li>
      <li className="hover:underline text-lightGray ">
        <NavLink to="/nd-health-ai-blog">{blog}</NavLink>
      </li>
      <li className="hover:underline text-lightGray ">
        <NavLink to="/contactus">{contactus} </NavLink>
      </li>
    </div>
  );
};

const Footer = () => {
  const { t } = useTranslation();

  const {
    diabetes,
    dyslipedimia,
    pcod,
    obesity,
    psoriasis,
    antiaging,
    nafld,
    ndhealthDesc,
    ourPrograms,
    otherLinks,
    privacy,
    terms,
    association,
  } = t("headerAndFooter");
  return (
    <footer className="flex flex-col gap-5 xs:gap-0 bg-mediumBlue py-2 text-base border-t border-mediumGray text-white w-full">
      <div className="flex md:flex-row flex-col md:gap-5 gap-10 py-2 pb-4 items-center px-5 sm:items-start">
        {/*******   Logo & Social Media Links    ************/}

        <div className="flex flex-col sm:items-start items-center gap-3 md:w-1/4 sm:w-1/2 mx-auto md:mx-0">
          <img
            src={Logo}
            loading="lazy"
            alt="logo"
            className="hover:scale-105 ease-out duration-500 w-56 "
          />

          <p className="">{ndhealthDesc}</p>
        </div>

        <div className="flex xxs:flex-row flex-col gap-5 md:w-1/2 w-full justify-around items-start">
          {/*******   NavLinks   ********/}

          <ul className="">
            <NavLinks />
          </ul>

          {/*********   Programs Section    ************/}

          <div className="">
            <ul className="flex flex-col gap-2 ">
              <li className="font-semibold text-base ">{ourPrograms}</li>
              <li className="hover:underline text-lightGray">
                <NavLink to="/programs/diabetes-reversal-program">
                  {diabetes}
                </NavLink>
              </li>
              <li className="hover:underline text-lightGray ">
                <NavLink to="/programs/dyslipidemia-treatment-program">
                  {dyslipedimia}
                </NavLink>
              </li>
              <li className="hover:underline text-lightGray ">
                <NavLink to="/programs/pcod-management-program">{pcod}</NavLink>
              </li>
              <li className="hover:underline text-lightGray ">
                <NavLink to="/programs/obesity-management-program">
                  {obesity}
                </NavLink>
              </li>
              <li className="hover:underline text-lightGray ">
                <NavLink to="/programs/psoriasis-treatment-program">
                  {psoriasis}
                </NavLink>
              </li>
              <li className="hover:underline text-lightGray ">
                <NavLink to="/programs/anti-aging-program">{antiaging}</NavLink>
              </li>
              <li className="hover:underline text-lightGray ">
                <NavLink to="/programs/nafld-program">{nafld}</NavLink>
              </li>
            </ul>
          </div>

          {/***********    Privacy and Terms    *****************/}
          <div className="flex flex-col gap-3 text-base ">
            <p className="font-semibold ">{otherLinks}</p>

            <Link to="/privacy-policy">
              <p className="inline cursor-pointer hover:underline text-lightGray">
                {privacy}
              </p>
            </Link>
            <Link to="/terms-and-conditions">
              <p className="inline cursor-pointer hover:underline text-lightGray">
                {terms}
              </p>
            </Link>
          </div>

          {/************** Association **************/}

          {/* <div className="flex flex-row items-center lg:gap-4 gap-2 text-white">
          <p className="xxs:text-sm text-xs">{association}</p>

          <img
            src={twinHealthLogo}
            className="xs:w-24 w-20"
            alt="twinHealthLogo"
          />
        </div> */}
        </div>

        <div className="md:w-1/4 w-full flex items-center justify-center">
          {/*********** Social Media Component **************/}
          <SocialMedia />
        </div>
      </div>
      {/***********    Copy rights    ************/}
      <p
        dir="ltr"
        className="sm:text-base text-sm text-center py-3 border-t border-mediumGray"
      >
        &copy; ndhealth.ai All Rights Reserved.
      </p>
    </footer>
  );
};

export default Footer;
