import { Link } from "react-router-dom";
import post1 from "../../Images/Home/post_1[1]-03.png";
import precise from "../../Images/Home/precise_img.webp";
import { FaCheckCircle } from "react-icons/fa";

import { useTranslation } from "react-i18next";
import Aos from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";

const Dysfunctional = () => {
  const { t } = useTranslation();

  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);

  const { dysfunction, benefits } = t("home");

  const {
    title,
    miniTitle1,
    miniDesc1,
    miniTitle2,
    miniDesc2,
    miniTitle3,
    miniDesc3,
    knownBtn,
  } = dysfunction;

  const { benefTitle, para1, para2, para3, para4, para5 } = benefits;

  return (
    <section className="flex flex-col px-2 overflow-hidden">
      <div className="flex flex-col xl:gap-24 gap-10 xs:items-center">
        <h2 className="lg:text-4xl pt-5 md:text-3xl sm:text-2xl text-base font-semibold max-w-[50rem] text-center mx-auto text-mediumBlue">
          {title}
        </h2>

        <div className="lg:flex lg:justify-between xl:gap-20 lg:gap-5 lg:mx-5 xl:mx-auto">
          <img
            data-aos="fade-right"
            data-aos-duration="1000"
            data-aos-anchor-placement="center-center"
            src={post1}
            loading="lazy"
            className="w-[35rem] rounded"
            alt="Goobye-to-Diabetics-Img"
          />
          <div className="flex flex-col sm:gap-5 gap-3 items-start px-1 xs:px-5 max-w-[35rem] rounded">
            <p
              data-aos="fade-left"
              data-aos-duration="700"
              className="text-sm sm:text-base"
            >
              <span className="sm:text-xl ">
                <strong className="xxs:text-base sm:text-xl">
                  {miniTitle1}
                </strong>
              </span>
              <br />
              {miniDesc1}
            </p>

            <p
              data-aos="fade-left"
              data-aos-duration="1000"
              className="text-sm sm:text-base"
            >
              <span className="sm:text-xl ">
                <strong className="text-base sm:text-xl">{miniTitle2}</strong>
              </span>
              <br />
              {miniDesc2}
            </p>

            <p
              data-aos="fade-left"
              data-aos-duration="1200"
              className="text-sm sm:text-base"
            >
              <span className="sm:text-xl ">
                <strong className="text-base sm:text-xl">{miniTitle3}</strong>
              </span>
              <br /> {miniDesc3}
            </p>

            <Link to={"/contactus"}>
              <button className="sm:py-3 sm:px-10 py-1 px-4 styleButton">
                {knownBtn}
              </button>
            </Link>
          </div>
        </div>

        {/************ Benefits of choosing Twin Section ***********************/}

        <div className="lg:flex lg:justify-between xl:gap-20 lg:gap-5 lg:mx-5 xl:mx-auto xxs:pb-10 pb-5">
          <div
            data-aos="fade-left"
            className=" flex flex-col xxs:gap-5 gap-3 relative max-w-[40rem]"
          >
            <h2
              data-aos="fade-up"
              className="lg:text-4xl md:text-3xl sm:text-2xl text-base text-mediumBlue font-semibold pattern pl-8"
            >
              {benefTitle}
            </h2>
            <p className="flex items-center gap-5 text-sm sm:text-base pt-5">
              <span className="text-darkGreen">
                <FaCheckCircle />
              </span>
              {para1}
            </p>

            <p className="flex items-center gap-5 text-sm sm:text-base">
              <span className="text-darkGreen">
                <FaCheckCircle />
              </span>
              {para2}
            </p>

            <p className="flex items-center gap-5 text-sm sm:text-base">
              <span className="text-darkGreen">
                <FaCheckCircle />
              </span>
              {para3}
            </p>

            <p className="flex items-center gap-5 text-sm sm:text-base">
              <span className="text-darkGreen">
                <FaCheckCircle />
              </span>
              {para4}
            </p>

            <p className="flex items-center gap-5 text-sm sm:text-base">
              <span className="text-darkGreen">
                <FaCheckCircle />
              </span>
              {para5}
            </p>
          </div>
          <div className="max-w-96 rounded">
            <img
              data-aos="fade-left"
              src={precise}
              loading="lazy"
              className=""
              alt="preciseImg"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Dysfunctional;
